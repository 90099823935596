<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
             <h5>MESAS</h5>
          </CCardHeader>
          <CCardBody>
            <b-row>
         
              <b-col cols="12" sm="12"  md="7" lg="9">
                <b-tabs content-class="mt-3" align="center">
                  <b-tab v-for="(room, it1) in rooms" :key="it1" :title="room.name" :active="room.active" >

                        <grid-layout :layout.sync="room.tables"
                                    :col-num="colNum"
                                    :row-height="30"
                                    :is-draggable="draggable"
                                    :is-resizable="resizable"
                                    :vertical-compact="true"
                                    :use-css-transforms="true"
                                >
                                <grid-item v-for="(item,it2 ) in room.tables" :key="it2" 
                                      :static="item.static"
                                      :x="item.x"
                                      :y="item.y"
                                      :w="item.w"
                                      :h="item.h"
                                      :i="item.i"
                                  >
                                <b-button :disabled="item.state == 0 || item.state == 9" @click="ViewOrder(item.id_table)"  type="button" :variant="Background(item.state)" :class="'w-100 h-100'">
                                   <small class="size-name-table">{{item.i}}</small> <br> <small :class="'size-name-moso '+ (item.precount == 1 ? 'text-success':'') ">{{item.moso_name}}</small>
                                </b-button>
                            </grid-item>
                        </grid-layout>

                        <br>
                        <b-row  class="justify-content-md-center">
                          <b-col md="2">
                            <div class="table-responsive height-table">
                              <table class="table table-hover table-bordered">
                                <thead>
                                  <tr>
                                    <td  class="text-center text-white bg-success w-50">Libre</td>
                                    <td class="text-center text-white bg-danger w-50">Ocupado</td>
                                    <!-- <td class="text-center text-white bg-secondary">Inactivo</td>
                                    <td class="text-center text-white bg-info">Pasadiso</td> -->
                                  </tr>
                                </thead>
                              </table>
                            </div>
                          </b-col>
                        </b-row>
                  </b-tab>
                </b-tabs>
              </b-col>
              <b-col cols="12" sm="12"  md="5" lg="3">
                <CCard>
                  <CCardHeader class="text-center">
                    <h5>Información de la mesa</h5>
                  </CCardHeader>
                  <CCardBody>
                    <b-form id="Form" @submit.prevent="Validate" autocomplete="off">
                      <b-row class="justify-content-center">
                          <b-col cols="7" sm="7" md="7" lg="7">
                            <b-form-group label="Sala:">
                              <b-form-input disabled type="text" v-model="order.room_name"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <b-col cols="5" sm="5" md="5" lg="5">
                            <b-form-group label="Mesa:">
                              <b-form-input disabled type="text" v-model="order.table_name"></b-form-input>
                              <small v-if="errors.id_table" class="form-text text-danger" >Seleccione una mesa</small>
                            </b-form-group>
                          </b-col>

                          <b-col cols="6" sm="6" md="6" lg="6" v-if="type_order == 'new'">
                            <b-form-group label="Adultos:">
                              <b-input-group>
                                <b-input-group-append>
                                  <b-button size="sm"  @click="UpdateQuantityPaxs(1,'-','adults')" variant="primary">-</b-button>
                                </b-input-group-append>
                                <b-form-input size="sm" type="number" @change="UpdateQuantityPaxs(1,'*','adults')" class="text-center" v-model="order.pax_adults"></b-form-input>
                                <b-input-group-append>
                                  <b-button size="sm" @click="UpdateQuantityPaxs(1,'+','adults')" variant="primary">+</b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6" sm="6" md="6" lg="6" v-if="type_order == 'new'">
                            <b-form-group label="Niños:">
                              <b-input-group>
                                <b-input-group-append>
                                  <b-button size="sm"  @click="UpdateQuantityPaxs(1,'-','kids')" variant="primary">-</b-button>
                                </b-input-group-append>
                                <b-form-input size="sm" type="number" @change="UpdateQuantityPaxs(1,'*','kids')" class="text-center" v-model="order.pax_kids"></b-form-input>
                                <b-input-group-append>
                                  <b-button size="sm" @click="UpdateQuantityPaxs(1,'+','kids')" variant="primary">+</b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          

                          <b-col md="12" v-if="type_order == 'new'">
                            <b-form-group label="Código de Mozo:">
                              <b-input-group>
                                <b-form-input type="password" autocomplete="off" v-model="order.moso_code" class="form-control" ></b-form-input>
                                <b-input-group-append>
                                  <b-button type="button" @click="SearchMoso" variant="primary"><i class="fas fa-sign-in-alt"></i></b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          


                          <b-col md="12" v-if="type_order == 'new'">
                            <hr>
                          </b-col>

                          <b-col md="12" v-if="type_order == 'new'">
                            <b-form-group label="Mozo:">
                                <b-form-select v-model="order.id_moso_master" :options="mozos" class="form-control" ></b-form-select>
                                <small v-if="errors.id_moso_master" class="form-text text-danger" >Seleecione un moso</small>
                            </b-form-group>

                            <b-form-group label="Clave Maestra:">
                              <b-input-group>
                                <b-form-input type="password" autocomplete="off" v-model="order.master_key" class="form-control" ></b-form-input>
                                <b-input-group-append>
                                  <b-button type="button" @click="ValidateMasterKey" variant="primary"><i class="fas fa-sign-in-alt"></i></b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                    

                          <b-col md="12" :class="(type_order == 'edit' ? 'b-block':'d-none')">
                            <b-form-group label="Mozo:">
                              <b-form-input disabled type="text" v-model="order.moso_name"></b-form-input>
                              <small v-if="errors.id_moso" class="form-text text-danger" >Ingrese el moso</small>
                            </b-form-group>
                          </b-col>

                      </b-row>

                      <!-- <b-row :class="(type_order == 'new' ? 'b-block':'d-none') + ' justify-content-md-center'">
                          <b-col md="6">
                            <b-form-group>
                              <b-button class="form-control" @click="Validate" type="button" variant="primary" title="Ocupar mesa"><i class="fas fa-sign-in-alt"></i></b-button>
                            </b-form-group>
                          </b-col>
                      </b-row> -->

                  
                      <b-row  :class="'justify-content-center '+ (type_order == 'edit' ? 'b-block':'d-none')">

                          <b-col md="12" v-if="type_order == 'edit'">
                            <b-form-group label="Código de Mozo:">
                              <b-input-group>
                                <b-form-input type="password" autocomplete="off" v-model="order.moso_code" class="form-control" ></b-form-input>
                                <b-input-group-append>
                                  <b-button type="button" @click="InputTable" variant="primary"><i class="fas fa-sign-in-alt"></i></b-button>
                                  <b-button type="button"  :disabled="!Permission('BoxAdd')" @click="InputTableBox" variant="success"><i class="fas fa-sign-in-alt"></i></b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                          <!-- <b-col md="4">
                            <b-form-group>
                              <b-button size="sm" class="w-100" type="button" variant="primary" @click="NewOrder" title="Ver mesa"><i class="fas fa-eye"></i></b-button>
                            </b-form-group>
                          </b-col> -->

                          <b-col cols="4" sm="4" md="4" lg="4">
                            <b-form-group>
                              <b-button size="sm" :disabled="!Permission('OrderDelete')" class="w-100" type="button" variant="danger" @click="ConfirmCancelOrder(order.id_order)" title="Liberar mesa"><i class="fas fa-ban"></i></b-button>
                            </b-form-group>
                          </b-col>

                          <b-col cols="4" sm="4" md="4" lg="4">
                            <b-form-group>
                              <b-button :disabled="!Permission('OrderEdit')" size="sm" class="w-100" type="button" variant="warning" @click="ModalTableChange(order.id_order)" title="Cambiar mesa"><i class="fas fa-exchange-alt"></i></b-button>
                            </b-form-group>
                          </b-col>

                          <b-col cols="4" sm="4" md="4" lg="4">
                            <b-form-group>
                              <b-button size="sm" class="w-100" type="button" variant="warning" @click="DataPrint(order.id_order)" title="Imprimir Precuenta"><i class="fas fa-print"></i></b-button>
                            </b-form-group>
                          </b-col>

                          <b-col cols="4" sm="4" md="4" lg="4">
                            <b-form-group>
                              <b-button  :disabled="!Permission('BoxAdd')" size="sm" class="w-100" type="button" variant="info" @click="Payment(order.id_order)" title="Cobrar"><i class="fas fa-money-check-alt"></i></b-button>
                            </b-form-group>
                          </b-col>

                          <b-col cols="4" sm="4" md="4" lg="4">
                            <b-form-group>
                              <b-button  :disabled="!Permission('BoxAdd')" size="sm" class="w-100" type="button" variant="success" @click="Invoice(order.id_order)" title="Facturar"><i class="fas fa-file-invoice-dollar"></i></b-button>
                            </b-form-group>
                          </b-col>

                          <b-col cols="4" sm="4" md="4" lg="4">
                            <b-form-group>
                              <b-button size="sm" class="w-100" type="button" variant="primary" title="Enviar Comanda de Fondo" @click="ValidateSendLetterFund(order.id_order)"><i class="fas fa-mail-bulk"></i></b-button>
                            </b-form-group>
                          </b-col>

                          <b-col cols="4" sm="4" md="4" lg="4">
                            <b-form-group>
                              <b-button size="sm" class="w-100" type="button" variant="secondary" title="Stock de Platos" @click="ModalStockLetterShow()"><i class="fas fa-file-alt"></i></b-button>
                            </b-form-group>
                          </b-col>
                      </b-row>


              

                    </b-form>
                  </CCardBody>
                </CCard>
              
              </b-col>
            </b-row>
            

                
          </CCardBody>
        </CCard>
      </CCol>
  
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <TableChange />
    <ModalStockLetter />
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
import { GridLayout, GridItem } from "vue-grid-layout";
import LoadingComponent from './../pages/Loading'
import TableChange from './components/TableChange'
import ModalStockLetter from './../components/ModalStockLetter'

export default {
  name: "UsuarioList",
  components: {
    GridLayout,
    GridItem,
    vSelect,
    LoadingComponent,
    TableChange,
    ModalStockLetter,
  },
  data() {
    return {
      isLoading:false,
      module:'Order',
      role:1,
      rooms:[],
      type_order:'',
      order:{
        id_warehouse: 1,
        id_table:'',
        id_client:'',
        id_moso:'',
        id_moso_master:'',
        room_name:'',
        table_name:'',
        moso_code:'',
        moso_name:'',
        state:1,
        master_key:'',
        pax_adults:0,
        pax_kids:0,
      },
      client:{id:1,name:'Clientes varios - 00000000'},
      clients:[],

      draggable: false,
      resizable: false,
      colNum: 30,
      index: 0,
      mozos:[],
      errors:{
        id_table:false,
        id_client:false,
        id_moso:false,
        paxs:false,

        id_moso_master:false,
        master_key:false,
      }

    };
  },

  mounted() {
    EventBus.$on('ListTable', () => {
      this.ListTable();
    });

    this.ListTable();
    this.ListMozos();
  },
  methods: {
    Background,
    Permission,
    ListTable,
    ViewOrder,
    Validate,
    Addorder,
    NewOrder,

    ShowModalClients,
    SearchClients,
    SearchMoso,
    UpdateQuantityPaxs,
    ModalTableChange,

    ConfirmCancelOrder,
    CancelOrder,

    Payment,
    Invoice,
    InputTable,
    ListMozos,
    ValidateMasterKey,
    AddorderMasterKey,


    ModalStockLetterShow,

    ValidateSendLetterFund,
    SendLetterFund,
    PrintCommand,

    InputTableBox,

    DataPrint,
    PrintOrder,
  },

  computed: {
    ...mapState(["url_base","url_server_command"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    business: function () {
      let business = window.localStorage.getItem("business");
      business = JSON.parse(JSON.parse(je.decrypt(business)));
      return business;
    },
  },
};

function UpdateQuantityPaxs(quantity,operation,type) {
   if (operation == "+") {
      if (type == "adults") {
          this.order.pax_adults = parseFloat(this.order.pax_adults) + parseFloat(quantity);
      }
      if (type == "kids") {
        this.order.pax_kids = parseFloat(this.order.pax_kids) + parseFloat(quantity);
      }
  }
  if (operation == "-") {
      if (type == "adults") {
          this.order.pax_adults = parseFloat(this.order.pax_adults) - parseFloat(quantity);
      }
      if (type == "kids") {
        this.order.pax_kids = parseFloat(this.order.pax_kids) - parseFloat(quantity);
      }
  }

   if (operation == "*") {
      if (type == "adults") {
        this.order.pax_adults = this.order.pax_adults.length == 0 ? 0 : parseFloat(this.order.pax_adults);
      }
      if (type == "kids") {
        this.order.pax_kids = this.order.pax_kids.length == 0 ? 0 : parseFloat(this.order.pax_kids);
      }
  }

  if (this.order.pax_adults  < 1) {
      this.order.pax_adults  = 1;
  }
  if (this.order.pax_kids < 0) {
      this.order.pax_kids = 0;
  }
     
}


function ShowModalClients() {
  
}

function ModalStockLetterShow() {
  EventBus.$emit('ModalStockLetterShow');
}

function SearchClients(search, loading) {
  
    let me = this;
    let url = this.url_base + "search-clients/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.clients = response.data.result;
            loading(false);
      })
    }
    
}

function ListMozos() {
  let me = this;
  let url = this.url_base + "moso/list-active";
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role,},
  })
  .then(function (response) {
    me.mozos = [];
    me.mozos.push({value:'',text:'-- Seleccione --'});
    if (response.data.status == 200) {
      
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.mozos.push({value:element.id_moso,text:element.description});
      }
     
    }
  })
  
}

function ViewOrder(id_table) {
  let me = this;
  let url = this.url_base + "order/get-order-active/"+id_table;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role,},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.order.id_table = response.data.result.table.id_table;
      me.order.room_name = response.data.result.table.room_name;
      me.order.table_name = response.data.result.table.name;
      me.order.id_order = response.data.result.order.id_order;
      me.order.id_moso = response.data.result.order.id_moso;

      me.order.moso_name = response.data.result.order.moso_name;
      me.order.client_name = response.data.result.order.client_name;
      me.order.code = response.data.result.order.code;
      me.order.moso_code = '';
      me.type_order = "edit";
    } else if(response.data.status == 404){
      me.order.id_table = response.data.result.table.id_table;
      me.order.room_name = response.data.result.table.room_name;
      me.order.table_name = response.data.result.table.name;
      me.order.moso_name = "";
      me.order.client_name = "";
      me.order.code = "";
      me.order.moso_code = '';
      me.type_order = "new";
      me.order.pax_adults = 0;
      me.order.pax_kids = 0;
    }else{
      me.order.id_table = "";
      me.order.room_name = "";
      me.order.moso_code = '';
      me.order.table_name = "";
      me.order.pax_adults = 0;
      me.order.pax_kids = 0;
      Swal.fire({ icon: 'error', text:response.data.message , timer: 3000,})
    }
    me.isLoading = false;
  })
}

function NewOrder() {
  if (this.order.id_order.length == 0) {
    Swal.fire({ icon: 'warning', text: 'No hay un pedido seleccionado', timer: 2000,}); 
    return false;
  }
  this.$router.push({
    name: "OrderAdd",
    params: { id_order: je.encrypt(parseInt(this.order.id_order)) },
  });
}

function Invoice(id_order) {
  this.$router.push({
    name: "SaleAddOrder",
    params: { id_order: je.encrypt(parseInt(id_order)) },
  });
}

function Background(state) {
  let background = "";
  switch (state) {
    case 0: background = "secondary"; break;
    case 1: background = "success"; break;
    case 2: background = "danger"; break;
    case 9: background = "info"; break;
  }
  return background;
 
}

function ListTable() {
  let me = this;
  let url = this.url_base + "table/list-active-by-user/"+this.user.id_user;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: 1},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.rooms = response.data.result;
    } else {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
  });
}

function SearchMoso() {
  let me = this;
  let url = this.url_base + "moso/get-by-code";
  let data = {
    code: me.order.moso_code
  };

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module, role: this.role,},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.order.moso_name = response.data.result.name;
      me.order.id_moso = response.data.result.id_moso;
      me.Validate();
    }else{
      me.order.moso_name = "";
      me.order.id_moso = "";
      Swal.fire({ icon: 'error', text: "No se ha encontrado una coincidencia" , timer: 3000,})
    }
    me.isLoading = false;
  })
}


function InputTable() {
  let me = this;
  let url =  this.url_base + "moso/get-by-code";
  let data = {
      code: me.order.moso_code
  };

   axios({
    method: "POST",
    url: this.url_base + "moso/get-by-code",
    data: data,
    headers: { token: this.token, module: this.module, role: this.role,},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      if (response.data.result.type == 1 || response.data.result.type == 2) {
        if (me.order.id_order.length == 0) {
          Swal.fire({ icon: 'warning', text: 'No hay un pedido seleccionado', timer: 2000,}); 
          return false;
        }
        me.$router.push({
          name: "OrderAdd",
          params: { id_order: je.encrypt(parseInt(me.order.id_order))},
        });
      }else{
        if (response.data.result.id_moso == me.order.id_moso) {
          me.order.moso_code = '';
          if (me.order.id_order.length == 0) {
            Swal.fire({ icon: 'warning', text: 'No hay un pedido seleccionado', timer: 2000,}); 
            return false;
          }
          me.$router.push({
            name: "OrderAdd",
            params: { id_order: je.encrypt(parseInt(me.order.id_order)) },
          });
        }else{
          me.order.moso_code = '';
          Swal.fire({ icon: 'error', text: "No se ha encontrado una coincidencia" , timer: 3000,})
        }

      }
    }else{
      me.order.moso_code = '';
      Swal.fire({ icon: 'error', text: "No se ha encontrado una coincidencia" , timer: 3000,})
    }
    me.isLoading = false;
  })


}

function Validate() {
  this.errors.id_client = this.client == null ? true : false;
  this.errors.id_table = this.order.id_table.length == 0 ? true : false;
  this.errors.id_moso = this.order.id_moso.length == 0 ? true : false;
  this.errors.paxs = parseFloat(this.order.pax_adults) + parseFloat(this.order.pax_kids) <= 0 ? true : false;
  

  if (this.errors.id_client == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_table == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_moso == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.paxs == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Ingrese el numero de paxs', timer: 2000,}); return false;}else{ this.validate = false; }
  
  let me = this;

  // Swal.fire({
  //   title: 'Esta seguro de ocupar la mesa '+this.order.table_name +'?',
  //   icon: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Si, Estoy de Acuerdo!'
  // }).then((result) => {
  //   if (result.isConfirmed) {
  //     this.Addorder();
  //   }
  // })

  this.Addorder();
}

function Addorder() {
  let me = this;
  me.isLoading = true;
  let url = me.url_base + "order/add";
  me.order.id_client = me.client.id;
  me.order.id_user = me.user.id_user;
  let data = me.order;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role,},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.$router.push({
          name: "OrderAdd",
          params: { id_order: je.encrypt(parseInt(response.data.result.id_order)) },
        });
        Swal.fire({ icon: 'success', text: response.data.message,timer: 2000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 2000})
      }
      me.isLoading = false;

    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 2000})
      me.isLoading = false;
    });
}


function ValidateMasterKey() {
  this.errors.id_client = this.client == null ? true : false;
  this.errors.id_table = this.order.id_table.length == 0 ? true : false;
  this.errors.id_moso_master = this.order.id_moso_master.length == 0 ? true : false;
  this.errors.master_key = this.order.master_key.length == 0 ? true : false;
  this.errors.paxs = parseFloat(this.order.pax_adults) + parseFloat(this.order.pax_kids) <= 0 ? true : false;

  if (this.errors.id_client == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_table == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_moso_master == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Seleccione un mozo', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.paxs == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Ingrese el numero de paxs', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.master_key == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Ingrese la clave maestra', timer: 2000,}); return false;}else{ this.validate = false; }
  

  let me = this;

  // Swal.fire({
  //   title: 'Esta seguro de ocupar la mesa '+this.order.table_name +'?',
  //   icon: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Si, Estoy de Acuerdo!'
  // }).then((result) => {
  //   if (result.isConfirmed) {
  //     this.AddorderMasterKey();
  //   }
  // })

  this.AddorderMasterKey();
}

function AddorderMasterKey() {
  let me = this;
  me.isLoading = true;
  let url = me.url_base + "order/add-master-key";
  me.order.id_client = me.client.id;
  me.order.id_user = me.user.id_user;
  me.order.id_moso = me.order.id_moso_master;
  let data = me.order;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role,},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.$router.push({
          name: "OrderAdd",
          params: { id_order: je.encrypt(parseInt(response.data.result.id_order)) },
        });
        Swal.fire({ icon: 'success', text: response.data.message,timer: 2000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 2000})
      }
      me.isLoading = false;

    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 2000})
      me.isLoading = false;
    });
}


function ModalTableChange(id_order) {
  let data = {
    id_order: id_order,
    role : this.role,
  }
  EventBus.$emit('ModalTableChangeShow',data);
}


function ConfirmCancelOrder(id_order) {

  let me = this;
  // Swal.fire({
  //   title: 'Esta seguro de liberar la mesa?',
  //   icon: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Si, Estoy de Acuerdo!'
  // }).then((result) => {
  //   if (result.isConfirmed) {
  //     this.CancelOrder(id_order);
  //   }
  // })

  this.CancelOrder(id_order);
}

function CancelOrder(id_order) {

  let me = this;
  me.isLoading = true;
  let url = me.url_base + "order/cancel/"+id_order;
  axios({
    method: "delete",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.ListTable();
        Swal.fire({ icon: 'success', text: response.data.message,timer: 2000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 2000})
      }
      me.isLoading = false;

    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 2000})
      me.isLoading = false;
    });
}

function Payment(id_order) {
  this.$router.push({
    name: "OrderComplete",
    params: { id_order: je.encrypt(parseInt(id_order)) },
  });
}

function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}




function DataPrint(id_order) {
  let me = this;
  let url = this.url_base + "order/data-print/"+id_order;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.PrintOrder(response.data.result);
      }
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function PrintOrder(info) {
  let url = 'http://localhost/print-comanda/consumirapi.php';
  var data = new FormData(); 
  data.append("data",JSON.stringify(info));
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}



function ValidateSendLetterFund(id_order) {
    // Swal.fire({
    //   title: 'Esta seguro de enviar la comanda de fondo?',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Si, Estoy de Acuerdo!'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     this.SendLetterFund();
    //   }
    // })

    this.SendLetterFund(id_order);
}

function SendLetterFund(id_order) {
  let me = this;
  let url = me.url_base + "order/send-letter-fund";
  let data = {
    id_order: id_order,
  }
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: 1},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.PrintCommand(response.data.result)
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function PrintCommand(info) {
  let url = this.url_server_command;
  var data = new FormData(); 
  data.append("data",JSON.stringify(info));
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}

function InputTableBox() {
  let id_order = this.order.id_order;
  this.$router.push({
    name: "OrderAdd",
    params: { id_order: je.encrypt(parseInt(id_order)) },
  });
}

</script>
<style scoped>
.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}
.columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
/*************************************/
.remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
    color: blue;
}
.edit {
    position: absolute;
    left: 2px;
    top: 0;
    cursor: pointer;
    color: blue;
}
.vue-grid-layout {
    background: #E4ECF9;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: #80A8FF;
    border: 1px solid #0182FF;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

.size-name-table{
  font-size: 15px;
  font-weight: 700;
}

.size-name-moso{
  font-size: 10px;
  font-weight: 300;
}
</style>